import React, { useEffect } from "react"
import { connect } from "react-redux"
import { fetchAppointments } from "../../state"
import { Layout } from "../../components/Layout"
import { AppointmentsSection } from "../../style/appointments"
import { AppointmentTable } from "../../components/AppointmentTable"
import { BallBeat } from "react-pure-loaders"

function AppointmentsContainer({
  appointmentsData,
  fetchAppointments,
  user = {},
}) {
  useEffect(() => {
    // console.log("fetchAppointments being called")
    if (appointmentsData.appointments.length === 0) {
      fetchAppointments(user)
    }
  }, [fetchAppointments])

  return (
    <Layout>
      <AppointmentsSection>
        <h1>Appointments </h1>

        {appointmentsData.loading ? (
          <>
            <br />
            <br />
            <h4>
              Loading appointments{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={appointmentsData.loading}
                />
              </span>{" "}
            </h4>
          </>
        ) : appointmentsData.error ? (
          <>
            <br />
            <br />
            <h4>Error ⚠️ Fetching appointements</h4>
            <br />
            <p style={{ color: "grey" }}>{appointmentsData.error}</p>
          </>
        ) : appointmentsData.appointments.length === 0 ? (
          <>
            {" "}
            <br />
            <br />
            <h4>No appointements found</h4>
          </>
        ) : (
          <>
            {/* <h1>Appointments </h1> */}
            <br />
            <AppointmentTable appointments={appointmentsData.appointments} />
          </>
        )}
      </AppointmentsSection>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    appointmentsData: state.appointments,
    user: state.login.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAppointments: (user) => dispatch(fetchAppointments(user)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentsContainer)
