import React from "react"
import { Table } from "./Table"
import { TableStyles } from "../style/globalStyles"
import Moment from "moment"

//dummy commit
export const AppointmentTable = ({ appointments }) => {
  const orderStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "Booked":
        return "teal"
      case "Confirmed":
        return "green"
      case "Completed":
        return "steelblue"
      case "Cancelled":
        return "red"
      case "Ongoing":
        return "darkorange"
      default:
        return "gray"
    }
  }
  const columns = [
    {
      Header: "Appointment ID",
      accessor: "orderSeqId",
    },
    {
      Header: "Appointment Date",
      accessor: (appointment) =>
        appointment.appointmentDate
          ? Moment(appointment.appointmentDate).format("DD MMM YYYY")
          : "NA",
    },
    {
      Header: "Appointment Status",
      accessor: "orderStatusDesc",
      Cell: (appointment) => (
        <span
          style={{
            color: orderStatusColor(appointment.value),
          }}
        >
          {appointment.value}
        </span>
      ),
    },
    {
      Header: "Doctor",
      accessor: (appointment) =>
        appointment.doctorId
          ? `${appointment.doctorFirstName} ${appointment.doctorLastName}`
          : appointment.professionalId
            ? `${appointment.professionalFirstName} ${appointment.professionalLastName}`
            : "NA",
    },
    {
      Header: "Doctor Speciality",
      accessor: (appointment) =>
        appointment.doctorSpecialityDesc
          ? appointment.doctorSpecialityDesc
          : appointment.professionalSpecialityDesc
            ? appointment.professionalSpecialityDesc
            : "NA",
    },
    {
      Header: "Patient",
      accessor: (appointment) =>
        appointment.patientId
          ? `${appointment.patientFirstName} ${appointment.patientLastName}`
          : "NA",
    },
    {
      Header: "Appointment Reason",
      accessor: (appointment) =>
        appointment.appointmentReasonLookupMap
          ? appointment.appointmentReasonLookupMap
            .map((lookUp) => {
              return lookUp.lookupDescription
            })
            .join(", ")
          : "NA",
    },
  ]

  var data = appointments

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e) => console.log(),
        }
      }}
      // Pass the page url here to navigate to onClick
      path={"/appointments/appointment"}
      columns={columns}
      data={data}
    />
  )

  return <TableStyles>{appointments ? tableRender : " "}</TableStyles>
}
